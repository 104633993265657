<template>

    <Modal
        :visible="walkCategoriesModalVisible"
        size="xlarge"
        :title="isEditing ? walkCatToEdit ? walkCatToEdit.name : 'Create a Walk Type' : walkCatToEdit?.name"
        @onClose="toggleModal"
    >
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
            </svg>
        </template>

        <CategoriesForm
            :isEditing="isEditing"
            :catToEdit="walkCatToEdit"
            @onSubmit="walkCatCreatedOrUpdated"
            ref="categoriesForm"
        />

        <template #footer-buttons>
            <DeleteButtonWithConfirmation v-if="!isEditing" :message="`Are you sure you wish to delete the category '${walkCatToEdit?.name}'? This action cannot be undone.`" @onDelete="catDeleted" />
            <Button v-if="!isEditing" variety="primary" @click="isEditing = true">Edit</Button>
            <Button
                v-if="!isEditing"
                variety="plain"
                @click="toggleModal"
            >
                Close
            </Button>
            <Button
                v-if="isEditing"
                variety="plain"
                @click="toggleModal"
            >
                Cancel
            </Button>
            <Button
                v-if="isEditing"
                variety="primary"
                @click="$refs.categoriesForm.onSubmit()"
            >
                Save
            </Button>
        </template>
    </Modal>

    <Card title="Walk Types">
        <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 dark:text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
            </svg>
        </template>

        <template #buttons>
            <Button
                variety="primary"
                @click="addCatClicked"
            >
                Add
            </Button>
        </template>

        <div v-if="walkCategories && walkCategories.length" class="flex flex-col gap-y-2 dark:text-gray-100">
            <div class="p-1 grid grid-cols-2 font-bold text-gray-600 dark:text-gray-100">
                <div>Type</div>
                <div>Price</div>
            </div>
            <div class="grid grid-cols-2 p-1 hover:bg-gray-100 cursor-pointer rounded-lg border border-gray-100 dark:border-gray-900 shadow p-2 mb-1 dark:bg-gray-600" v-for="cat in walkCategories" :key="cat" @click="catClicked(cat)">
                <div class="break-all">{{cat.name}}</div>
                <div>&pound;{{ cat.price_per_minute.toFixed(2) }}</div>
            </div>

        </div>
        <div v-else class="text-gray-500">
            No walk types have been created for the business.
        </div>
    </Card>

</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, ref} from "vue";
import Card from "../../../components/Card.vue";
import useBusinessStore from "../useBusinessStore";
import DeleteButtonWithConfirmation from "../../../components/forms/DeleteButtonWithConfirmation.vue";
import CategoriesForm from "./CategoriesForm.vue";
import WalkCategory from "../types/WalkCategory";

export default defineComponent({
    name: "WalkCategoriesCard",
    components: {CategoriesForm, DeleteButtonWithConfirmation, Card},
    props:{
      walkCategories :{
          type: Array,

      }
    },
    setup(props){
        const businessStore = useBusinessStore();
        const walkCategories = computed(() => businessStore.walkCategories);
        const walkCategoriesModalVisible = ref(false);
        const isEditing = ref(false);
        const walkCatToEdit = ref(undefined as WalkCategory | undefined);

        const toggleModal = () => {
            walkCategoriesModalVisible.value = !walkCategoriesModalVisible.value;
        }

        function addCatClicked() {
            walkCatToEdit.value = undefined;
            isEditing.value = true;
            walkCategoriesModalVisible.value = true;
        }

        function catClicked(cat: WalkCategory) {
            walkCatToEdit.value = cat;
            isEditing.value = false;
            walkCategoriesModalVisible.value = true;
        }

        async function walkCatCreatedOrUpdated(cat: WalkCategory) {
            try {
                if (walkCatToEdit.value?.id) {
                    await businessStore.walkCategoryUpdated({ ...cat, id: walkCatToEdit.value.id });
                } else {
                    await businessStore.walkCategoryAdded(cat);
                }

                closeModal();
            }
            catch (error){}
        }

        async function catDeleted() {
            try{
                await businessStore.walkCategoryDeleted(walkCatToEdit.value as WalkCategory);
                closeModal();
            }
            catch (error){}
        }

        function closeModal() {
            walkCategoriesModalVisible.value = false;
            isEditing.value = false;
        }

        return {
            walkCategories,
            isEditing,
            walkCategoriesModalVisible,
            walkCatToEdit,
            toggleModal,
            addCatClicked,
            catDeleted,
            catClicked,
            walkCatCreatedOrUpdated
        }
    }
})
</script>

<style scoped>

</style>
