<template>
    <div class="text-gray-600 dark:text-gray-100"
         :class="[mediaFlags.sm.lessThanOrEq? 'p-2 border mt-2' : 'grid', hasVat? 'grid-cols-10' : 'grid-cols-8'] ">
        <div class="p-0" :class="[!mediaFlags.sm.lessThanOrEq? 'editable-field multi-select-container col-span-3 border-2 dark:border-gray-800' : 'pb-2']">
            <div v-if="mediaFlags.sm.lessThanOrEq" class="font-bold mb-1">
                Walk Type
            </div>
            <div>
                <MultiSelectDropdown
                    id="walk-categories"
                    :selectors="walkCategories"
                    valueProp="id"
                    track-by="name"
                    label="name"
                    :value="walkCategoryId"
                    :dog-list="customerDogs"
                    @change="(value) => handleWalkCategoryChange(value)"
                />
                <div class="text-red-500 mt-1">
                    {{ errors[`lines[${index}].walk_category_id`] }}
                </div>
            </div>
        </div>
        <div class="p-0" :class="[!mediaFlags.sm.lessThanOrEq? 'editable-field col-span-3 border-2 border-l-0 dark:border-gray-800' : 'pb-2']">
            <div v-if="!line.invoice">
                <div v-if="mediaFlags.sm.lessThanOrEq" class="font-bold mb-1">
                    Customer / Dog
                </div>
                <div>
                    <CustomerDogsMultiSelector
                        :value="dog"
                        :dog-list="customerDogs"
                        @update:value="(value) => handleDogChange(value)" />
                    <div class="text-red-500 mt-1">
                        {{ errors[`lines[${index}].dog_id`] }}
                    </div>
                </div>
                <div v-if="dog" class="text-xs">
                    <ScheduleLineSurchargeList
                        :schedule-line-id="index"
                        :surcharge-list="surchargesList"
                        :default-surcharges="dogSurcharges"
                        @on-surcharge-select="handleSurchargeChange"
                    />
                </div>
            </div>
            <div v-else-if="dog" class="ml-2 mt-2">
                <span>{{ line.dog.name }}</span>
            </div>
        </div>
        <div class="p-0" :class="[!mediaFlags.sm.lessThanOrEq? 'editable-field col-span-1 border-2 border-l-0 dark:border-gray-800' : '']">
            <div v-if="mediaFlags.sm.lessThanOrEq" class="font-bold mb-1">
               Cost
            </div>
            <MoneyInput
                :isEditing="isEditing"
                :value="cost"
                @update:value="(value) => cost = value"
            />
        </div>
        <div class="p-0" :class="[!mediaFlags.sm.lessThanOrEq? 'editable-field col-span-1 border-2 border-l-0 dark:border-gray-800' : '']" v-if="hasVat">
            <div v-if="mediaFlags.sm.lessThanOrEq" class="font-bold mb-1">
                VAT
            </div>
            <MoneyInput
                :isEditing="false"
                :value="vat"
            />
        </div>
        <div class="p-0" :class="[!mediaFlags.sm.lessThanOrEq? 'editable-field col-span-1 border-2 border-l-0 dark:border-gray-800' : '']" v-if="hasVat">
            <div v-if="mediaFlags.sm.lessThanOrEq" class="font-bold mb-1">
                Price
            </div>
            <MoneyInput
                :isEditing="false"
                :value="price"
            />
        </div>
        <div class="p-0" :class="[!mediaFlags.sm.lessThanOrEq? 'col-span-1 pl-1 flex' : 'pt-2 text-right']" v-if="isEditing && allowRemove">
            <Button @click.prevent="handleRemoveLine()" variety="warning" class="self-center">
                Remove
            </Button>
        </div>
    </div>
</template>

<script lang="ts">
import BinIcon from "../../../components/icons/BinIcon.vue";
import Button from "../../../components/forms/Button.vue";
import DropdownSelector from "../../../components/forms/DropdownSelector.vue";
import {computed, defineComponent, PropType, ref, watch} from "vue";
import {ScheduleBlockLine} from "../types/ScheduleBlockLine";
import CustomerDogsMultiSelector from "../../customer/components/CustomerDogsMultiSelector.vue";
import useBusinessStore from "../../business/useBusinessStore";
import MoneyUtils from "../../../utils/MoneyUtils";
import MultiSelectDropdown from "../../../components/forms/MultiSelectDropdown.vue";
import CustomerDog from "../../customer/types/CustomerDog";
import ChargeableCategory from "../../business/types/ChargeableCategory";
import {useMediaQuery} from "../../core/useMediaQuery";
import ScheduleLineSurchargeList from "./ScheduleLineSurchargeList.vue";
import Surcharge from "../../business/types/Surcharge";

export default defineComponent({
    components: {
        ScheduleLineSurchargeList,
        CustomerDogsMultiSelector,
        DropdownSelector,
        Button,
        BinIcon,
        MultiSelectDropdown
    },
    props:{
        line: {
            type: Object as PropType<ScheduleBlockLine>,
            required: true,
        },
        surchargesList: {
            type: Array,
            required: false
        },
        dogList: {
            type: Array,
            required: false
        },
        index: {
            type: Number,
            required: true,
        },
        errors:{
            required: false,
            type: Object,
        },
        hasVat: {
            type: Boolean,
            required: false,
            default: false
        },
        isEditing: {
            type: Boolean,
            required: false,
            default: false
        },
        allowAdd: {
            type: Boolean,
            required: false,
            default: false
        },
        allowRemove: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'onRemoveLine',
        'onAddLine',
        'onDogSelect',
        'onSurchargeToggle'
    ],
    setup: function (props, context) {

        const customerDogs: CustomerDog[] = props.dogList;
        let selectedDog = props.line.dog_id;
        const { mediaFlags } = useMediaQuery("tailwind");

        const getCategory = (categoryId: number, categories: ChargeableCategory[]): ChargeableCategory => {
            for(let i = 0; i < categories.length; i++) {
                let category = categories[i];

                if(category.id === categoryId) {
                    return category;
                }
            }

            return categories[0];
        }

        const getCategoryPrice = (categoryId: number, categories: ChargeableCategory[]): number => {
            let category = getCategory(categoryId, categories);

            return category?.price_per_minute ?? 0;
        }

        const calculateCost = (walkCategoryId: number, dogCategoryId: number): number => {
            let walkCategoryPrice = getCategoryPrice(walkCategoryId, walkCategories.value);
            let dogCategoryPrice = getCategoryPrice(dogCategoryId, dogCategories.value);

            let surchargesPrice = 0;
            dogSurcharges.value?.forEach(x => {
                if(x.applied){
                    surchargesPrice = surchargesPrice + parseFloat(x.price_per_minute);
                }
            })

            return walkCategoryPrice + dogCategoryPrice + surchargesPrice;
        }

        const updateSource = () =>  {
            props.line.walk_category = walkCategory.value;
            props.line.walk_category_id = walkCategoryId.value;
            props.line.dog = dog.value;
            props.line.dog_id = dog.value?.id;

            if(dog.value?.customer) {
                props.line.customer = dog.value?.customer;
                props.line.customer_id = dog.value?.customer?.id;
            }
            props.line.surcharges = dogSurcharges.value ? dogSurcharges.value.filter(function(s){
                return s.applied === true;
            }) : [];

            props.line.cost = MoneyUtils.convertToPence(cost.value);
            props.line.vat = MoneyUtils.convertToPence(vat.value);
            props.line.price = MoneyUtils.convertToPence(price.value);
        }

        const handleWalkCategoryChange = (value: number) => {
            walkCategoryId.value = value;
            cost.value = calculateCost(walkCategoryId.value, dogCategoryId.value as number);
        }

        const dogSurcharges = ref(props.line.surcharges ? props.line.surcharges.map(function(s){
            return s
        }) : []);

        const handleDogChange = (value: CustomerDog) => {
            dog.value = value;
            dogSurcharges.value = dog.value?.surcharges?.map(sc => {
                let found = props.surchargesList?.find(sl => sl.id == sc) as Surcharge;
                if(found){
                    found.applied = true;
                }
                return found;
            }) as Surcharge[];
            cost.value = calculateCost(walkCategoryId.value, dogCategoryId.value as number);
            context.emit("onDogSelect", selectedDog, dog.value?.id);
            selectedDog = dog.value?.id;
        }

        const handleSurchargeChange = (scheduleLineId: number, value: Surcharge) => {
            let idx = dogSurcharges.value.findIndex(x => x.id == value.id);
            if(idx == -1){
                dogSurcharges.value.push(value);
            }
            else
            {
                dogSurcharges.value[idx] = value;
            }
            cost.value = calculateCost(walkCategoryId.value, dogCategoryId.value as number);
        }

        const handleRemoveLine = () => {
            context.emit("onRemoveLine");
        }

        const handleAddLine = () => {
            context.emit("onAddLine");
        }

        const businessStore = useBusinessStore();

        const walkCategories = computed(() => businessStore.walkCategories);

        const walkCategoryId = ref(props.line.walk_category_id ? props.line.walk_category_id : walkCategories.value[0].id);
        const walkCategory = computed(()=> getCategory(walkCategoryId.value, walkCategories.value));

        const dogCategories = computed(() => businessStore.dogCategories);

        const dog = ref(props.line.dog);
        const dogName = computed(() => dog.value?.name);
        const dogCategoryId = computed(() => dog.value?.dog_category_id);

        const cost = ref(props.line?.cost ? MoneyUtils.convertToPounds(props.line?.cost) : calculateCost(walkCategoryId.value, dogCategoryId.value as number));
        const vat = computed(() => MoneyUtils.calculateVat(props.hasVat, cost.value));
        const price = computed(() => cost.value + vat.value);

        updateSource();

        watch([walkCategoryId, dog, cost, dogSurcharges],
            () => {
                updateSource();
            },
            {
                deep: true
            });

        return {
            customerDogs,
            walkCategories,
            handleRemoveLine,
            handleAddLine,
            handleWalkCategoryChange,
            handleDogChange,
            handleSurchargeChange,

            walkCategoryId,
            walkCategory,

            dog,
            dogName,
            dogSurcharges,

            cost,
            vat,
            price,
            mediaFlags
        }
    },
})
</script>


<style>
.editable-field input{
    border: 0;
}

.editable-field .v-money3{
    border:0;
    box-shadow: none;
    border-radius: 0;
}
</style>
