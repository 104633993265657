import {defineStore} from "pinia";
import DogCategory from "./types/DogCategory";
import WalkCategory from "./types/WalkCategory";
import BusinessApiClient from "../../api/BusinessApiClient";
import SlimBusinessUser from "./types/SlimBusinessUser";
import Expireable from "../../types/Expireable";
import moment from "moment";
import Business from "./types/Business";
import WalkCategoryUtils from "./utils/WalkCategoryUtils";
import DogCategoryUtils from "./utils/DogCategoryUtils";
import BusinessType from "./types/BusinessType";
import Surcharge from "./types/Surcharge";
import SurchargeUtils from "./utils/SurchargeUtils";

const useBusinessStore = defineStore({
    id: "business",
    state: () => ({
        activeBusinessLoading: true,
        expireableBusinessUsers: {
            expires: moment().add(-1, "minutes").utc(),
            data: []
        } as Expireable<SlimBusinessUser[]>,
        activeBusiness: {} as Business,
        walkCategories: [] as WalkCategory[],
        dogCategories: [] as DogCategory[],
        surcharges: [] as Surcharge[]
    }),
    actions: {
        async userHasAuthenticated() {
            await this.loadBusiness();
            await this.getDogCategories();
            await this.getWalkCategories();
            await this.getSurcharges();
        },

        async loadBusiness() {
            this.activeBusinessLoading = true;
            const businessInfoResponse = await BusinessApiClient.businessInfo();
            if (businessInfoResponse.success) {
                this.activeBusiness = businessInfoResponse.data!;
            }
            this.activeBusinessLoading = false;
        },

        async businessUpdated(newBusinessData: Business) {
            this.activeBusinessLoading = true;
            const businessUpdateResponse = await BusinessApiClient.updateBusinessInfo(newBusinessData)

            if (businessUpdateResponse.success) {
                this.activeBusiness = businessUpdateResponse.data!;
            }
            this.activeBusinessLoading = false;

            return businessUpdateResponse.success;
        },

        async loadBusinessUsers() {
            if (this.expireableBusinessUsers && this.expireableBusinessUsers.expires > moment().utc()) {
                return;
            }

            const businessUsersResponse = await BusinessApiClient.listUsersForBusiness();
            if (businessUsersResponse.success) {
                this.expireableBusinessUsers = {
                    expires: moment().add(10, "minutes").utc(),
                    data: businessUsersResponse.data!
                };
            }
        },

        async updateBusinessAvatar(imageData: any) {
            const uploadImageResponse = await BusinessApiClient.updateBusinessAvatar(imageData);
            if (uploadImageResponse.success) {
                this.activeBusiness.image_path = uploadImageResponse.data.image_path;
            }
        },

        async deleteBusinessAvatar() {
            const deleteImageResponse = await BusinessApiClient.deleteBusinessAvatar();
            if (deleteImageResponse.success) {
                this.activeBusiness.image_path = deleteImageResponse.data!.image_path;
            }
        },

        async getSurcharges() {
            const surchargesResponse = await BusinessApiClient.listSurchargesForBusiness();

            if (surchargesResponse.success) {
                this.surcharges = surchargesResponse.data!.map(SurchargeUtils.deApify);
            }
        },

        async surchargeAdded(surcharge: Surcharge) {
            const updatedSurchargeResponse = await BusinessApiClient.addSurcharge(SurchargeUtils.apify(surcharge));

            if (updatedSurchargeResponse.success && updatedSurchargeResponse.data) {
                this.surcharges.push(SurchargeUtils.deApify(updatedSurchargeResponse.data));
                return true;
            }

            return false;
        },

        async surchargeDeleted(surcharge: Surcharge) {
            const deleteSurchargeResponse = await BusinessApiClient.deleteSurcharge(surcharge);
            if (deleteSurchargeResponse.success) {
                const index = this.surcharges.findIndex((cat: Surcharge) => cat.id === surcharge.id);
                if (index != -1) {
                    this.surcharges.splice(index, 1);
                }
            }
        },

        async surchargeUpdated(surcharge: DogCategory) {
            const updatedSurchargeResponse = await BusinessApiClient.updateSurcharge(SurchargeUtils.apify(surcharge));
            if (updatedSurchargeResponse.success) {
                const id = updatedSurchargeResponse.data!.id;
                if (!id) return;
                const index = this.surcharges.findIndex((cat: Surcharge) => cat.id === id);
                if (index != -1) {
                    this.surcharges[index] = SurchargeUtils.deApify(updatedSurchargeResponse.data!);
                }
            }
        },

        async getDogCategories() {
            const dogCategoriesResponse = await BusinessApiClient.listDogCategoriesForBusiness();

            if (dogCategoriesResponse.success) {
                this.dogCategories = dogCategoriesResponse.data!.map(DogCategoryUtils.deApify);
            }
        },

        async dogCategoryAdded(dogCategory: DogCategory) {
            const updateCategoryResponse = await BusinessApiClient.addDogCategory(DogCategoryUtils.apify(dogCategory));

            if (updateCategoryResponse.success && updateCategoryResponse.data) {
                this.dogCategories.push(DogCategoryUtils.deApify(updateCategoryResponse.data));
                return true;
            }

            return false;
        },

        async dogCategoryDeleted(dogCategory: DogCategory) {
            const deleteCategoryResponse = await BusinessApiClient.deleteDogCategory(dogCategory);
            if (deleteCategoryResponse.success) {
                const index = this.dogCategories.findIndex((cat: DogCategory) => cat.id === dogCategory.id);
                if (index != -1) {
                    this.dogCategories.splice(index, 1);
                }
            }
        },

        async dogCategoryUpdated(dogCategory: DogCategory) {
            const updateCategoryResponse = await BusinessApiClient.updateDogCategory(DogCategoryUtils.apify(dogCategory));
            if (updateCategoryResponse.success) {
                const id = updateCategoryResponse.data!.id;
                if (!id) return;
                const index = this.dogCategories.findIndex((cat: DogCategory) => cat.id === id);
                if (index != -1) {
                    this.dogCategories[index] = DogCategoryUtils.deApify(updateCategoryResponse.data!);
                }
            }
        },

        async getWalkCategories() {
            const walkCategoriesResponse = await BusinessApiClient.listWalkCategoriesForBusiness();
            if (walkCategoriesResponse.success) {
                this.walkCategories = walkCategoriesResponse.data!.map(WalkCategoryUtils.deApify);
            }
        },

        async walkCategoryAdded(walkCategory: WalkCategory) {
            const updateCategoryResponse = await BusinessApiClient.addWalkCategory(WalkCategoryUtils.apify(walkCategory));

            if (updateCategoryResponse.success && updateCategoryResponse.data) {
                this.walkCategories.push(WalkCategoryUtils.deApify(updateCategoryResponse.data));
                return true;
            }

            return false;
        },

        async walkCategoryDeleted(walkCategory: WalkCategory) {
            const deleteCategoryResponse = await BusinessApiClient.deleteWalkCategory(walkCategory);

            if (deleteCategoryResponse.success) {
                const index = this.walkCategories.findIndex((cat: WalkCategory) => cat.id === walkCategory.id);
                if (index != -1) {
                    this.walkCategories.splice(index, 1);
                }
            }
        },

        async walkCategoryUpdated(walkCategory: WalkCategory) {
            const updateCategoryResponse = await BusinessApiClient.updateWalkCategory(WalkCategoryUtils.apify(walkCategory));

            if (updateCategoryResponse.success) {
                const id = updateCategoryResponse.data!.id;
                if (!id) return;
                const index = this.walkCategories.findIndex((cat: WalkCategory) => cat.id === id);
                if (index != -1) {
                    this.walkCategories[index] = WalkCategoryUtils.deApify(updateCategoryResponse.data!);
                }
            }
        },

    },
    getters: {
        businessUsers: (state) => state.expireableBusinessUsers.data,
        vatRegistered: (state) => !!state.activeBusiness.vat_number
    }
});

export default useBusinessStore;
