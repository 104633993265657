<template>
    <Form @submit="onSubmit">
        <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2 divide divide-y">
                <FormComponent :hasTopPadding="false">
                    <TextInput
                        id="cat-name"
                        label="Name"
                        placeholder="Type"
                        :isEditing="isEditing"
                        :value="name"
                        :errors="nameError"
                        :required="true"
                        @update:value="v => (name = v)"
                    />
                </FormComponent>

                <FormComponent>
                    <TextInput
                        id="price-per-minute"
                        label="Fixed Price"
                        placeholder="0.50"
                        :isEditing="isEditing"
                        :value="pricePerMinute"
                        :errors="pricePerMinuteError"
                        :required="true"
                        @update:value="v => (pricePerMinute = v)"
                    />
                </FormComponent>
            </div>

        </div>

        <template #buttons>
            <Button
                v-if="!isEditing"
                variety="plain"
                @click.stop="$emit('onCancel')"
            >
                Close
            </Button>
            <Button
                v-if="isEditing"
                variety="plain"
                @click.stop="$emit('onCancel')"
            >
                Cancel
            </Button>
            <Button 
                v-if="isEditing"
                variety="primary"
                @click.stop="onSubmit"
            >
                Save
            </Button>
        </template>
    </Form>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onErrorCaptured, onMounted, PropType} from "vue";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import WalkCategory from "../types/WalkCategory";

export default defineComponent({
    props: {
        catToEdit: {
            type: Object as PropType<WalkCategory | undefined>,
            required: false,
            default: undefined
        },
        isEditing: {
            type: Boolean,
            required: true
        }
    },
    setup(props, context) {

        const schema = yup.object({
            id: yup.string().nullable(),
            name: yup.string().required("Name is required.").max(255),
            price_per_minute: yup.string()
                .required("A fixed price is required")
                .min(0, "Price must be a positive value")
                .max(10000, "Price must be less than 10,000")
        });

        const { handleSubmit } = useForm({
            validationSchema: schema,
        })

        const { value: id, errorMessage: idError } = useField("id");
        const { value: name, errorMessage: nameError } = useField("name");
        const { value: pricePerMinute, errorMessage: pricePerMinuteError } = useField("price_per_minute");

        if (props.catToEdit) {
            name.value = props.catToEdit.name;
            pricePerMinute.value = props.catToEdit.price_per_minute;
            id.value  = props.catToEdit.id;
        }

        const onSubmit = handleSubmit(values => {
            context.emit("onSubmit", values);
        });

        return {

            name,
            nameError,

            pricePerMinute,
            pricePerMinuteError,
            
            onSubmit
        }
    },
})
</script>
